<template>
	<div class="pied_de_page">
		<div class="reference" v-if="$store.state.offre_active.id_clipper && $store.state.offre_active.id_clipper != ' '">{{ $store.state.offre_active.id_clipper }}</div>
		<div class="reference" v-else>{{ $store.state.offre_active.id_offre }}</div>
		<div v-if="$store.state.offre_active.page2_intitule && $store.state.offre_active.page2_intitule != '-'" class="machine" v-html="$store.state.offre_active.page2_intitule" />
		<div v-else class="machine">
			<span v-if="$store.state.offre_active.langue == 'fr'">{{ $store.state.offre_active.machine.display_name_fr }}</span>
			<span v-if="$store.state.offre_active.langue == 'en'">{{ $store.state.offre_active.machine.display_name_en }}</span>
			<span v-if="$store.state.offre_active.langue == 'de'">{{ $store.state.offre_active.machine.display_name_de }}</span>
			<span v-if="$store.state.offre_active.langue == 'pl'">{{ $store.state.offre_active.machine.display_name_pl }}</span>
			<span v-if="$store.state.offre_active.langue == 'us'">{{ $store.state.offre_active.machine.display_name_us }}</span>
			<span v-if="$store.state.offre_active.langue == 'ro'">{{ $store.state.offre_active.machine.display_name_ro }}</span>
			{{ $store.state.offre_active.machine.name }}
		</div>
		<div class="date" v-if="$store.state.offre_active.langue == 'fr'">{{ $store.state.offre_active.date_modifiee | moment("D/MM/Y") }}</div>
		<div class="date" v-else>{{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }}</div>
		<div class="item">Page {{ numero }}</div>
	</div>
</template>
<script>
import moment from "moment";
export default {
	name: "PieddePage",
	props: {
		numero: Number,
	},
};
</script>

<style lang="scss"></style>
